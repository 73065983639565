import { useEffect, useState, useRef } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import LogoReversedImg from "../../App/images/ihsi-logo-reversed.svg";
import { Link } from "react-router-dom";
import ButtonRounded from "../ButtonRounded";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AvatarMenu from "./AvatarMenu";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { usePrintState } from "../../providers/printProvider";
import {
  AccountSettingsProvider,
  useAccountSettingsState,
  useAccountSettingsDispatch,
} from "../../providers/accountSettingsProvider";
import {
  NotificationProvider,
  useNotificationState,
  useNotificationDispatch,
} from "../../providers/notificationProvider";
import styles from "./header.module.scss";
import { Typography } from "@mui/material";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});

const css = {
  badge: {
    ".MuiBadge-badge": {
      top: ".5rem",
      backgroundColor: "#EF4720",
      color: "#FFF",
    },
  },
};
const useStyles = makeStyles((theme) =>
  createStyles({
    avatar: {
      //backgroundColor: `${theme.palette.primary.main} !important`,
      backgroundColor: "#cd9bf5 !important",
      fontSize: ".9rem !important",
      width: "32px",
      height: "32px",
    },
    avatarButton: {
      padding: "0 .3rem !important",
      marginLeft: "0 !important",
    },
  })
);

const LoggedInNavBar = (props) => {
  const { currentUser } = props;
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popupHidden, setPopupHidden] = useState(false);
  const [popupRemoved, setPopupRemoved] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [mouseoverTriggered, setMouseoverTriggered] = useState(false);
  const menuOpen = Boolean(anchorEl);

  const { onGetAccountSettings, onSetNotificationEnrollment, onClearSettingsErrors } = useAccountSettingsDispatch();
  const {
    getAccountSettingsError,
    accountSettingsData,
    getAccountSettingsSuccess,
    getAccountSettingsLoading,
    notificationEnrollmentSuccess,
    notificationEnrollmentLoading,
    notificationEnrollmentError,
    notificationEnrollmentData,
  } = useAccountSettingsState();
  const { notifyPopupSuccess, notifyPopupLoading, notifyPopupEnabled, clearNotifications } = useNotificationState();
  const { onToggleNotifyPopup } = useNotificationDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleHoverPopup = () => {
  //   if (!mouseoverTriggered) {
  //     setMouseoverTriggered(true);
  //     setTimeout(() => {
  //       setPopupHidden(true);
  //       setTimeout(() => {
  //         setPopupRemoved(true);
  //         onToggleNotifyPopup();
  //       }, "600");
  //     }, "2000");
  //   }
  // };

  useEffect(() => {
    onGetAccountSettings();
  }, []);

  useEffect(() => {
    if (accountSettingsData !== undefined && Object.keys(accountSettingsData).length > 0) {
      if (
        accountSettingsData.emailNotificationPopup !== null &&
        accountSettingsData.emailNotificationPopup !== undefined
      ) {
        if (accountSettingsData.emailNotificationPopup) {
          setShowPopup(true);
          setTimeout(() => {
            setPopupHidden(true);
            setTimeout(() => {
              onToggleNotifyPopup();
              setShowPopup(false);
            }, "600");
          }, "8000");
        } else {
          setShowPopup(false);
        }
      }
    }
  }, [accountSettingsData]);

  useEffect(() => {
    if (!notifyPopupEnabled) {
      setShowPopup(false);
    }
  }, [notifyPopupSuccess]);

  return (
    <>
      <div className={styles.navbar}>
        <Stack direction="row" spacing={1}>
          <div className={styles.popupWrapper}>
            {showPopup && (
              <>
                <div
                  className={`${styles.notifyPopup} ${popupHidden ? styles.hidden : ""}`}
                  //onMouseOver={handleHoverPopup}
                >
                  <Link className={styles.popupLink} to="/dashboard/notifications">
                    <p>
                      Email Notifications are currently off.
                      <br /> Please visit the dashboard to enable.
                    </p>
                  </Link>
                </div>
              </>
            )}

            <ButtonRounded className={styles.dashboardButton} isReversed={true} url="/dashboard" title="My Dashboard" />
          </div>
          {/* <IconButton className={classes.iconButton} aria-label="Notifications">
            <NotificationsIcon className={classes.notificationIcon} />
          </IconButton> */}
          <Tooltip title="Main Menu">
            <IconButton
              className={classes.avatarButton}
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              aria-label="Main Menu"
            >
              {currentUser &&
              currentUser.hasNotifications &&
              currentUser.hasNotifications > 0 &&
              !clearNotifications ? (
                <Badge sx={css.badge} badgeContent={currentUser.hasNotifications} color="secondary">
                  <Avatar className={classes.avatar}>
                    {currentUser && currentUser.avatarUrl ? currentUser.avatarUrl : ":)"}
                  </Avatar>
                </Badge>
              ) : (
                <Avatar className={classes.avatar}>
                  {currentUser && currentUser.avatarUrl ? currentUser.avatarUrl : ":)"}
                </Avatar>
              )}
            </IconButton>
          </Tooltip>
        </Stack>
      </div>
      <ThemeProvider theme={lightTheme}>
        <AvatarMenu
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleClose}
          onClick={handleClose}
          currentUser={currentUser}
        />
      </ThemeProvider>
    </>
  );
};

const LoggedOutNavBar = () => (
  <div className={styles.navbar}>
    <Stack direction="row" spacing={1}>
      <ButtonRounded
        className={styles.headerButton}
        variant="borderless"
        isReversed={true}
        url="/login"
        title="Login"
      />
      <ButtonRounded className={styles.headerButton} isReversed={true} url="/contact" title="Sign Up" />
    </Stack>
  </div>
);

const Header = (props) => {
  const { hideBg, isLoggedIn, isDarkMode, isLoggedInHome, currentUser } = props;
  const [sticky, setSticky] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const { printPreviewEnabled } = usePrintState();
  const { accountSettingsData } = useAccountSettingsState();
  const headerRef = useRef();
  const getHeaderHeight = () => {
    if (headerRef.current !== null) {
      const newHeight = headerRef.current.offsetHeight;
      if (headerHeight !== newHeight) {
        setHeaderHeight(newHeight);
      }
    }
  };

  useEffect(() => {
    getHeaderHeight();
  }, [headerHeight]);

  useEffect(() => {
    if (!sticky) {
      if (typeof window !== "undefined") {
        window.addEventListener("scroll", () => {
          getHeaderHeight();
          if (window.pageYOffset > 0) {
            setSticky(true);
          } else {
            setSticky(false);
          }
        });
      }
    }
  }, [sticky]);

  useEffect(() => {
    window.addEventListener("resize", getHeaderHeight);
  }, []);

  const headerHeightStyle = {
    height: headerHeight,
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div
        className={`${styles.headerWrapper} ${sticky ? styles.sticky : ""} ${printPreviewEnabled ? styles.hide : ""}`}
      >
        <div style={headerHeightStyle} className={styles.headerStickyHeight}></div>

        <header
          className={`${styles.header} ${sticky ? styles.headerStickyShadow : ""} ${hideBg ? styles.hideBackground : ""}
          ${isDarkMode ? styles.isDarkMode : ""} ${isLoggedInHome ? styles.isLoggedInHome : ""}`}
          ref={headerRef}
        >
          <div className="container">
            <div className={`${styles.headerContent} row middle-xs`}>
              <div className="col-xs col-md-4 col-lg-3">
                <Link className={styles.logoLink} to="/">
                  <div className={styles.logoWrap}>
                    <img className={styles.logo} src={LogoReversedImg} alt="IHSI Logo" />

                    {(accountSettingsData?.environmentLabel || window?.location?.hostname === "localhost") && (
                      <div className={styles.envStatus}>
                        v1.40.1{" "}
                        {accountSettingsData?.environmentLabel
                          ? accountSettingsData.environmentLabel
                          : window?.location?.hostname === "localhost"
                          ? "Local"
                          : ""}
                      </div>
                    )}
                  </div>
                </Link>
              </div>
              <div className={"col-xs"}>
                {isLoggedIn ? (
                  <LoggedInNavBar isLoggedIn={isLoggedIn} currentUser={currentUser} />
                ) : (
                  <LoggedOutNavBar />
                )}
              </div>
            </div>
          </div>
        </header>
      </div>
    </ThemeProvider>
  );
};

export default Header;
